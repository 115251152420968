/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* END OF RESET CSS */

/* MAIN VARIABLES */

:root {
  --background: #0f172a;
  --white: #fff;
  --title-white: #d0d6dc;
  --content: #a5b0bb;
  --red-accent: #b32a31;
  --medium-green: #12a996;
  --light-green: #1ce8ce;
  --dark-green: #0c5c52;
}

.gray-font {
  color: var(--content);
}

.red-font {
  color: var(--red-accent);
}

.lightgray-font {
  color: var(--title-white);
}

.white-font {
  color: var(--white);
}

.lightgreen-font {
  color: var(--light-green);
}

.blue-bg {
  background-color: var(--background);
}

.gray-bg {
  background-color: var(--title-white);
}

.green-bg {
  background-color: var(--dark-green);
}

.lightgreen-bg {
  background-color: var(--medium-green);
}

.raleway {
  font-family: "Raleway", sans-serif;
}

.title {
  font-size: 1.4rem;
}

.subtitle {
  font-size: 1.2rem;
}

.title-margin {
  margin: 0 8% 0;
}

.subtitle-margin {
  margin: 1.5rem 0 1rem;
}

.general-content {
  font-size: 1.1rem;
}

.lightbold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.line-height {
  line-height: 2rem;
}

.line-height-content {
  line-height: 1.5rem;
}

.focus {
  color: var(--white);
  font-weight: 500;
}

.margin-bottom-content {
  margin-bottom: 1rem;
}

/* BEGINNING OF ACTUAL STYLING */

.general-layout {
  margin: 0 8%;
}

.sticky-title {
  position: sticky;
  padding: 2rem 0 2rem;
  background-color: rgb(15, 23, 42, 0.8);
  top: 0;
  margin: 0 -8% 0;
}
/* MOBILE VERSION */
/* LANDING SECTION */

.landing-section {
  background-image: url(./img/bg-phone.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#title-border {
  padding-bottom: 1.2rem;
  border-bottom: 1px solid var(--content);
}

.icons-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1.2rem;
}

.icon {
  color: var(--title-white);
  width: 2.5rem !important;
  height: 2.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.icon:hover {
  color: var(--red-accent);
}

.btn-cont {
  margin: 1rem 0;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 105%;
  background-color: transparent;
  border: 2px solid var(--title-white);
  border-radius: 2px;
  color: var(--title-white);
  padding: 0.4rem 0.8rem;
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  cursor: pointer;
  background-color: var(--medium-green);
  border: 2px solid var(--medium-green);
}

.btn-icon {
  transition: transform 0.3s ease-in-out !important;
}

.btn:hover .btn-icon {
  transform: rotate(90deg);
}

/* ABOUT SECTION */

.img-about-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 3.5rem;
}

.img-about {
  width: 75vw;
}

/* SKILLS SECTION */

.skills-container {
  display: flex;
  flex-direction: column;
}

.skill-bar {
  border: none;
  display: flex;
  align-items: center;
  height: 2.4vh;
  margin-bottom: 1rem;
  width: 100%;
}

.skill-name {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 2.4vh;
  font-size: 0.7rem;
  background-color: var(--medium-green);
  color: var(--title-white);
  font-weight: 600;
}

.bar-container {
  border: none;
  flex: 1;
  height: 2.4vh;
  background-color: var(--white);
  overflow: hidden;
}

.bar {
  border: none;
  height: 2.4vh;
  background-color: var(--dark-green);
  animation: progress-animation 2s ease-in-out;
}

@keyframes progress-animation {
  0% {
    width: 0;
  }
}

/* PROJECTS SECTION */

.project-grid {
  grid-template-columns: 1fr;
}

.img-projects {
  width: 80%;
  margin-bottom: 1rem;
}

.subtitle {
  display: flex;
  justify-content: left;
  align-items: center;
}

.subtitle:hover {
  color: var(--medium-green);
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 1rem 1rem 0;
}

.skill {
  display: flex;
  justify-content: center;
  background-color: var(--dark-green);
  color: var(--light-green);
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 0.8rem;
}

/* RECOMMENDATIONS SECTION */

.name-referencer {
  font-size: 1.2rem;
}

.referencer-margin {
  margin: 1rem 0 2rem;
}

.referencer-grid {
  grid-template-columns: 1fr;
}

/* CONTACT SECTION */

.contact-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

a {
  color: var(--title-white);
}

a:hover {
  color: var(--medium-green);
}

a:visited {
  color: var(--title-white);
}

/* FOOTER SECTION */

.footer-cont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--medium-green);
  margin-top: 4rem;
}

.arrow-footer {
  color: var(--white);
  background-color: var(--red-accent);
  position: absolute;
  top: -25px;
  width: 3rem !important;
  height: 3rem !important;
  cursor: pointer;
}

.arrow-footer-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.icons-cont-footer {
  margin: 2.5rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.logo-footer {
  width: 30%;
}

.logo-footer-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.last-text-footer-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem 0 1.5rem;
}
/* DESKTOP VERSION */

@media screen and (min-width: 768px) {
  .sticky-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-section {
    background-image: url(./img/bg-desktop.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 95vw;
  }

  .img-about {
    width: 25%;
  }

  .about-text-cont {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
  }

  .skills-section{
    display: block;
    justify-content: center;
    align-items: center;
  }

  .skills-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 0 12rem 0;
  }

  .skill-bar {
    height: 3vh;
  }

  .skill-name {
    height: 3vh;
    font-size: 0.8rem;
    width: 80px;
  }

  .bar-container {
    height: 3vh;
  }

  .bar {
    height: 3vh;
  }

  section:nth-of-type(2) .bar span {
    padding: 0 15px 0 0;
    z-index: 1;
    display: block;
  }

  .project-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  .referencer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 0 10rem 0;
  }

  .content-contact-cont{
    margin: 0 10rem 0;
  }

  .logo-footer{
    width: 15%;
  }

  .last-footer{
    font-size: 1.2rem;
  }
}
